import { inject }        from 'aurelia-framework';
import { AppContainer }  from 'resources/services/app-container';
import { BaseViewModel } from 'base-view-model';

@inject(AppContainer)
export class BaseOrderedListViewModel extends BaseViewModel {

    canEdit = false;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Loads the full dataset
     */
    async loadFullData() {
        const response = await this.repository.search({});
        this.fullData  = response.data;
    }

    /**
     * Updates the order of the records
     */
    async updateOrder(repository) {
        const datatableData = this.datatable.instance.data;

        await this.fullData.forEach(row => {
            const datatableRow = datatableData.find(datatableRow => datatableRow.id === row.id);
            if (datatableRow) {
                row.order = Number(datatableRow.order);
            }
        });

        const response = await this.repository.updateOrder(this.fullData);

        if (response.status !== true) {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        this.datatable.instance.reload();
    }

    /**
     * Searches for records given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        // You can update this function to ensure it works well with pagination if needed
        return this.repository.search(criteria);
    }

    /**
     * Clear filter data and reload datatable
     */
    clearFilterAndNotify() {
        this.appContainer.eventAggregator.publish('datatable-filter-must-be-reseted', {
            listingId:      this.listingId,
            ignoredFilters: this.ignoredFilters,
        });
    }

}
